

















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import FundManagerCard from "@/modules/genprox/modules/AIFM/components/FundManagerCard.vue";
import FundKPIs from "@/modules/genprox/modules/AIFM/components/FundKPIs.vue";
import FundFinancials from "@/modules/genprox/modules/AIFM/components/FundFinancials.vue";
import FundChart from "@/modules/genprox/modules/AIFM/components/FundChart.vue";
import Chart from "@/modules/genprox/modules/AIFM/components/Chart.vue";
import DoughtnutChart from "@/modules/genprox/modules/AIFM/components/DoughtnutChart.vue";
import FundProducts from "@/modules/genprox/modules/AIFM/components/FundProducts.vue";
import FundTransactions from "@/modules/genprox/modules/AIFM/components/FundTransactions.vue";
import ManagementTeam from "@/modules/genprox/modules/AIFM/components/ManagementTeamCard.vue";
import FundInformationCard from "@/modules/genprox/modules/AIFM/components/FundInformationCard.vue";
import FundDataCard from "@/modules/genprox/modules/AIFM/components/FundDataCard.vue";
import FundHeader from "@/modules/genprox/modules/AIFM/components/FundHeader.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import { mapState } from "vuex";
import { Prop, Watch } from "vue-property-decorator";
import { FundManager, MTMember } from "@/modules/genprox/models/User";
import { Report } from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";
import Utils from "@/modules/shared/utils/utils";
import moment from 'moment';
import { LastTransactionsInterface } from "@/modules/genprox/modules/profile/models/lastTransactions.interface";
import { fundFinancialsInterface } from '../../profile/models/fundFinancials.interface';

@Component({
  components: {
    FundInformationCard,
    FundDataCard,
    ManagementTeam,
    FundManagerCard,
    SygniContainerTitle,
    FundKPIs,
    FundChart,
    DoughtnutChart,
    FundProducts,
    FundFinancials,
    FundTransactions,
    FundHeader,
    SygniLoader,
    Chart,
  },
  computed: {
    ...mapState('genprox', {
      managementTeam: (state: any) => state.managementTeam,
      activeUserContextId: (state: any) => state.activeUserData.context?.id
    }), ...mapState('regReporting', {
      fundManagerData: (state: any) => state.fundData,
      reports: (state: any) => state.reports.sort((reportA: Report, reportB: Report) => {
        return reportA.year < reportB.year ? 1 : -1;
      })
    })
  }
})
export default class CompanyDashboard extends Vue {
  @Prop() openCardId!: string;

  fundManagerData!: FundManager | any;
  accessModules: any = null;
  fundAdditionalData!: any;
  progressMax: number = 0;
  progressVal: number = 0;
  filters: any = {
    date: {
      value: '',
      placeholder: '',
    },
    type: 'all',
    company: '',
  };
  isLoading: boolean = true;
  managementTeam!: MTMember[];
  reports!: Array<Report>;
  activeUserContextId!: string;
  showCompanyKPIs: boolean = false;
  showFundFinancials: boolean = false;
  showTransactions: boolean = false;
  showCapitalStructure: boolean = false;
  ebitdaGraph: { x: string, y: number }[] = [];
  ebitdaLabel: string = '';
  companyKPIs: any = [
    {
      name: "Revenue",
      level: 0,
      value: {
        value: '---',
        currency: "PLN"
      }
    },
    {
      name: "Net profit (loss)",
      level: 0,
      value: {
        value: '---',
        currency: "PLN"
      }
    },
    {
      name: "EBITDA",
      level: 0,
      value: {
        value: '---',
        currency: "PLN"
      }
    },
    {
      name: "Cash",
      level: 0,
      value: {
        value: '---',
        currency: "PLN"
      }
    },
    {
      name: "EMPLOYMENT",
      level: 0,
      value: {
        value: '---',
        currency: "PLN"
      }
    }
  ];
  fundFinancials: fundFinancialsInterface = {
    date: '',
    periodType: '',
    data: [
      [
        {
          name: "Investment assets",
          level: 1,
          value: null
        },
        {
          name: "Cash level",
          level: 1,
          value: null
        },
        {
          name: "Non-investment assets",
          level: 1,
          value: null
        },
        {
          name: "Total Assets",
          level: 0,
          value: null
        }
      ],
      [
        {
          name: "Capital and Reserves",
          level: 1,
          value: null
        },
        {
          name: "Long-term liabilities",
          level: 1,
          value: null
        },
        {
          name: "Short-term liabilities & Provisions",
          level: 1,
          value: null
        },
        {
          name: "Total Liabilities",
          level: 0,
          value: null
        }
      ]
    ]
  };
  transactions: LastTransactionsInterface[] = [];
  capitalStructureGraph: {} | any = {};
  widgets: any[] = []

  get companyOptions() {
    return this.$store.getters['auth/getInvestedCompanies'];
  }

  get fundOptions() {
    return this.$store.getters['auth/getInvestedFunds'];
  }

  get useViewedId() {
    if (this.isPortfolioCompany) {
      if(this.activeUserData.context.context === 'company') {
        return false;
      } else {
        return true
      }
    } else {
      if (this.activeUserData.context.context === 'fund') {
        return false;
      } else {
        return true
      }
    }
  }

  get showLePicker() {
    if (this.isPortfolioCompany) {
      if(this.activeUserData.context.context === 'company') {
        return false;
      } else {
        if(this.companyOptions.length > 1) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (this.activeUserData.context.context === 'fund') {
        return false;
      } else {
        if(this.fundOptions.length > 1) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('company') ? true : false;
  }

  get nameSymbol(): string {
    return this.fundManagerData.name?.slice(0, 1);
  }

  get viewedId() {
    return this.activeUserData.context.context !== 'company' ? this.$store.getters['auth/getViewedCompanyId'] : this.activeUserContextId;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get activeUserRoles() {
    return this.$store.getters['genprox/activeUserData'].role;
  }

  userHasAccessTo(type: string | string[]) {
    let result = false;

    if (typeof type === 'string') {
      if (type === 'reporting') {
        if (this.activeUserData.role.reporting !== null) result = true;

      }
      if (type === 'capital') {
        if (this.activeUserData.role.capital !== null) result = true;
      }
    } else {
      type.forEach(i => {
        if (i === 'capital') {
          if (this.activeUserData.role.capital !== null) result = true;
        }
        if (i === 'portfolio') {
          if (this.activeUserData.role.portfolio !== null) result = true;
        }
      })
    }

    return result;
  }

  async getAccessToModule(type: string) {
    // if (this.accessModules === null) {
      this.accessModules = await this.$store.dispatch('genprox/getLegalEntityAccessModules', this.activeUserData.context.context !== 'company' ? this.viewedId : '');
    // }

    if (type === 'transaction') {
      return this.userHasAccessTo(['capital', 'portfolio']) ? (this.accessModules.capital || this.accessModules.portfolio) : false;
    }
    if (type === 'reporting') {
      return this.userHasAccessTo('reporting') ? this.accessModules.managementReporting : false;
    }
    if (type === 'capital') {
      return this.userHasAccessTo('capital') ? this.accessModules.capital : false;
    }
  }

  async updateData(date?: string) {
    this.isLoading = true;
    const searchedDate = date ? date : moment().format('YYYY-MM-DD');
    const type = this.filters.type === 'all' ? '' : this.filters.type;

    this.showCompanyKPIs = await this.getAccessToModule('reporting');
    if (this.showCompanyKPIs) {
      const companyKPIs = await this.$store.dispatch('genprox/getCompanyKPIs', {
        searchedDate,
        viewedContext: this.useViewedId ? this.viewedId : ''
      });

      if (companyKPIs) {
        this.companyKPIs = companyKPIs.data[0];
      }
    }

    this.showFundFinancials = await this.getAccessToModule('reporting');
    if (this.showFundFinancials) {
      const fundFinancials = await this.$store.dispatch('genprox/getKeyFinancials', {
        date: searchedDate,
        path: 'company',
        viewedContext: this.useViewedId ? this.viewedId : ''
      });
      if (fundFinancials) {
        this.fundFinancials = fundFinancials;
      } else {
        this.fundFinancials.data.forEach(el => {
          el.forEach((i: any) => {
            i.value = null;
          })
        })
      }
    }

    await this.getDashboardWidgets();

    this.showTransactions = await this.getAccessToModule('transaction');
    if (this.showTransactions) {
      const transactions = await this.$store.dispatch('genprox/getLastTransactions', {
        searchedDate,
        type,
        path: 'company',
        viewedContext: this.activeUserData.context.context !== 'company' ? this.viewedId : ''
      });
      if (transactions) {
        this.transactions = transactions;
      } else {
        this.transactions = [];
      }
    }

    this.showCapitalStructure = await this.getAccessToModule('capital');
    if (this.showCapitalStructure) {
      const capitalStructureGraph = await this.$store.dispatch('genprox/getCapitalStructureGraph', {
        searchedDate,
        type,
        viewedContext: this.activeUserData.context.context !== 'company' ? this.viewedId : ''
      });

      if (capitalStructureGraph) {
        this.capitalStructureGraph = capitalStructureGraph;
      } else {
        this.capitalStructureGraph = {};
      }
    }

    this.isLoading = false;
  }

  setDataDashboard(date: any): void {
    let dateTime = new Date(date);
    dateTime = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0);
    // date range definition
    const dateRange = [date, `${dateTime.getFullYear()}-${Utils.pad(dateTime.getMonth() + 1, 2)}-${Utils.pad(dateTime.getDate(), 2)}`];
    this.filters.date.value = dateRange;
    const filteredDate = dateRange[1];

    this.updateData(filteredDate);
  }

  async updateCompany() {
    await this.updateData();
  }

  async getDashboardWidgets() {
    this.widgets = await this.$store.dispatch('genprox/getCompanyWidgets', this.activeUserData.context.context !== 'company' ? this.viewedId : '')
  }

  setDatePlaceholder() {
    const date = this.filters.date.value[0];
    let dateTime = new Date(date);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = monthNames[dateTime.getMonth()];
    this.filters.date.placeholder = `${month}, ${dateTime.getFullYear()}`;
  }

  @Watch('filters.date.value') onDateChange() {
    this.setDatePlaceholder();
  }

  @Watch('filters.type') onTypeChange() {
    this.getDashboardWidgets();
    this.updateData();
  }

  async loadData() {
    if (this.activeUserData.context.context !== 'company') {
      await this.$store.dispatch('auth/getInvestedCompanies');
      await this.$store.dispatch('auth/getViewedCompany');
    }

    this.setDataDashboard(moment().format('YYYY-MM-DD'));

    this.$store.dispatch('genprox/getManagementTeam', this.viewedId);
    const fundData: FundManager = await this.$store.dispatch('genprox/getLeContextData', this.viewedId);
    this.$store.commit('regReporting/setFundData', fundData);
  }

  async mounted() {
    await this.loadData();
  }

  async reloadDashboard() {
    this.isLoading = true;
    await this.loadData();
  }

  addNewReport(): void {
    this.$router.push('reg-reporting/add-report');
  }
}

